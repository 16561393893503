@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0px;
  padding: 0px;
  background-color: rgba(244,236,230,1);
  overscroll-behavior: none;
}

.anton-regular {
  font-family: "Anton", sans-serif;
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "FiraSans";
  src: url("./assets/fonts/FiraSans-SemiBold.ttf");
}

@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/Inter-VariableFont_opsz_wght.ttf");
}

@font-face {
  font-family: "Cabinet Grotesk";
  src: url("./assets/fonts/CabinetGrotesk-Variable.ttf") format("opentype");
}

@font-face {
  font-family: "Anton";
  src: url("./assets/fonts/Anton-Regular.ttf");
}

@font-face {
  font-family: "IndieFlower";
  src: url("./assets/fonts/IndieFlower-Regular.ttf");
}

.gradient-button {
  background: linear-gradient(to right, rgba(59, 180, 232, 1), rgba(82, 154, 54, 1), rgba(254, 195, 48, 1));
  color: white;
  padding: 6px 20px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
}

.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.no-scrollbar::-webkit-scrollbar {
  display: none;  /* Chrome, Safari, and Opera */
}

/** For the pulse for quizzes played **/
.pulse-li {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
  margin: 0;
}

@keyframes pulse-animation {
  0% {
    transform: translate(-50%, -50%) scale(0);
    opacity: .8
  }
  90% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(3)
  }
  to {
    transform: translate(-50%, -50%) scale(0);
    opacity: 0
  }
}

.pulse:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  background: #f9e9a2;
  border-radius: 100%;
  opacity: .5;
  transform: translate(-50%,-50%) scale(3);
  animation: pulse-animation 2s infinite;
}

.pulse {
  position: relative;
  display: inline-block;
  vertical-align: 1px;
  width: 8px;
  height: 8px;
  margin: 0 8px;
  background: #ffdd40;
  color: transparent;
  border-radius: 100%;
  flex: 0 0 auto;
  animation: opacity-animation 1s linear;
}

.pulse.green {
  background: #00e600;
}

.scrollbar-hide {
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For IE and Edge */
}

.scrollbar-hide::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}